import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link as ReactLink } from 'react-router-dom';
import isInternalLink from '../../services/isInternalLink';
import clsx from 'clsx';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

const propTypes = {
  to: PropTypes.string.isRequired,
  blank: PropTypes.bool,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  blank: false,
  color: '',
  hoverColor: '',
  className: '',
};

export function getLinkClass(size, variant, bare, external) {
  return clsx('transition-colors font-medium cursor-pointer', {
    'inline-flex items-center gap-x-1 mr-auto md:mr-0':
      variant === 'withIcon' || external,
    'text-gray-900 hover:text-chocolate-600': !bare && variant !== 'secondary',
    'underline hover:text-gray-700': variant === 'secondary',
    'underline text-white hover:text-gray-200': variant === 'light',
    'text-sm': size === 'small',
  });
}
// variants = external || withIcon || underline

export default class Link extends Component {
  render() {
    const {
      to,
      href,
      children,
      blank,
      className,
      variant,
      bare,
      size,
      external = false,
      ...rest
    } = this.props;
    const isInternal = isInternalLink(to || href);

    const linkClass = clsx(
      'transition-colors font-medium',
      className,
      getLinkClass(size, variant, bare, external),
    );

    if (isInternal && !blank) {
      return (
        <ReactLink className={linkClass} to={href || to} {...rest}>
          {children}

          {external && <ArrowTopRightOnSquareIcon className="w-4 h-4" />}
        </ReactLink>
      );
    }

    return (
      <a
        className={linkClass}
        href={href || to}
        target={blank ? '_blank' : ''}
        rel="noopener noreferrer"
        {...rest}
      >
        {children}

        {external && <ArrowTopRightOnSquareIcon className="w-4 h-4" />}
      </a>
    );
  }
}

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;
